import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'George Soto', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Welcome to my Portfolio', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Welcome, my name is',
  name: 'George Soto',
  subtitle: 'A Unique Software Engineer',
  cta: '',
};

// ABOUT DATA
export const aboutData = {
  img: 'profilepic.jpeg',
  paragraphOne:
    'Self-motivated & Hard-working IT professional with proficient knowledge JavaScript, HTML, CSS, React, and Node JS. Bringing a strong ability to communicate effectively in a team environment and possess the willingness to learn and adapt to change. ',
  resume: 'https://drive.google.com/file/d/1RzxaygqwkNk14Sl_j6I0z0ieYzpX-Rf0/view?usp=sharing', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'chatapp.jpg',
    title: 'Chat App',
    info:
      'A small chat application using React and ChatEngine.io. Users can send messages and images through this application and supports user login.',
    info2: 'Username: TestUser Password: pass123',
    url: 'https://chatapp-soto.netlify.app/',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'tipcalc.jpg',
    title: 'Tip Calculator',
    info:
      'A React application using render props and calculating how much you should be tipping your local small business.',
    info2: '',
    url: 'https://tip-calc-soto.netlify.app/',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'weather-app.jpg',
    title: 'Weather App',
    info:
      'Weather application using nodejs for the server and utilizes handlebarsjs for the ui components.',
    info2: '',
    url: 'https://weather-app-soto.herokuapp.com/',
    repo: '', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: 'sotogeorge08@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/georgeasoto/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/gasoto25',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
